/* tslint:disable */
import { ApplicationRef, enableProdMode, NgModuleRef } from "@angular/core";
import { disableDebugTools } from "@angular/platform-browser";
import { Environment } from "./environment-interface";
import {
  getBaseOrigin,
  getBaseServerUrl,
  getCustomerGluConfig,
  getDefaultFirebaseConfig,
  getFirebaseConfig,
  getGenericDeviceId,
  getGenericToken,
  getIsProduction, getRVTryonConfig,
  getTruecallerTokenConfig,
  getUpstreamUrl,
  getWebviewVersion,
  googleClientIdConst
} from "./environment-utils";

const isSSR: boolean = typeof window === "undefined";
const isProduction: boolean = getIsProduction(isSSR);
const showDevModule: boolean = !isProduction;

isProduction && enableProdMode();

export const environment: Environment = {
  production: isProduction,
  showDevModule: showDevModule,
  baseServerUrl: getBaseServerUrl(isProduction), //Should be used only for SEO Meta URLs purposes
  apiBaseUrl: getBaseOrigin(isSSR, isProduction),
  upstreamApiServer: getUpstreamUrl(isProduction), //Used only for development mode
  env: isSSR ? process.env['ENVIRONMENT'] || "prod" : window["env"] || "prod",
  generic_token: getGenericToken(isSSR),
  generic_device_id: getGenericDeviceId(isSSR),
  customerGluConfig: getCustomerGluConfig(isSSR),
  googleClientId: googleClientIdConst,
  rvTryOnConfig: getRVTryonConfig(),
  truecallerConfig: getTruecallerTokenConfig(isSSR),
  exitPopup: false,
  firebaseConfig: getFirebaseConfig(isSSR),
  googleYoloEnabled: true,
  defaultFirebaseConfig: getDefaultFirebaseConfig(isSSR),
  webviewVersion: getWebviewVersion(),
  /** Angular debug tools in the dev console
   * https://github.com/angular/angular/blob/86405345b781a9dc2438c0fbe3e9409245647019/TOOLS_JS.md
   * @param modRef
   * @return {any}
   */
  decorateModuleRef(modRef: NgModuleRef<any>) {
    if (isProduction) {
      disableDebugTools();
      return modRef;
    } else {
      const appRef = modRef.injector.get(ApplicationRef);
      const cmpRef = appRef.components[0];

      let _ng = (window as any).ng;
      (window as any).ng.probe = _ng.probe;
      (window as any).ng.coreTokens = _ng.coreTokens;
      return modRef;
    }
  },
  ENV_PROVIDERS: [],
};
