import { Injectable } from "@angular/core";
import { AppCommonService } from "@services/app-common.service";
import { StorageService } from "@services/storage.service";
import { Subject, defer, merge, of, throwError, timer } from "rxjs";
import { delay, mergeMap, retryWhen, take, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthorizationUrls, TruecallerLoginUrls, commonApiUrls } from "../constants/api/api-constants";
import { ModeDevice } from "../constants/application/application-constants";
import { AuthApiStatus, AuthMessages } from "../constants/auth/auth-constants";
import { TruecallerLoginPrefix } from "../constants/configuration-constants/truecaller-config";
import { AppExactRouteConstants } from "../constants/routes/app-routes-constant";
import { FHRoutes } from "../constants/routes/fh-routes-constants";
import { RequestService } from "./request.service";
import { HttpService } from "./http.service";

declare var branch: any;
declare let Freshbots: any;
declare var google: any;
@Injectable({ providedIn: "root" })
export class AuthService {
  private isLoggedIn = new Subject<any>();
  cartcount: number = 0;
  redirectPostLogin: any;
  isEliteUserBoolean: boolean = false;
  isInitCalled: boolean = false;
  menuData: any;
  isScriptsLoaded: boolean = false;
  fcmTokenSubscription = null;
  truecallerEnable: boolean;
  private loginStateChangeSubject = new Subject<any>();
  public showLoginPopupSub = new Subject<any>();
  public loginSuccesSub: Subject<any>;
  beuatyBoxCartStateChange = new Subject<any>();
  public loadingWishlistBtn: boolean = false;
  loadingWishlistSubs = new Subject<any>();
  isSSR = typeof window === 'undefined';
  constructor(private commonService: AppCommonService,
    private storageService: StorageService,
    private requestService: RequestService,
    private httpService: HttpService) { }
  getLoginState(): any {
    return this.loginStateChangeSubject.asObservable();
  }

  setLoginState(loginState) {
    this.loginStateChangeSubject.next(loginState);
  }
  getBeautyoxCartState(): any {
    return this.beuatyBoxCartStateChange.asObservable();
  }
  setBeautyoxCartState(state) {
    this.beuatyBoxCartStateChange.next(state);
  }
  postToAPI(url: string, paramsObj: any, headersObj?: any, mapped?: any) {
    if (headersObj) {
      return this.httpService.postToAPI(url, paramsObj, { headersObj });
    } else {
      return this.httpService.postToAPI(url, paramsObj);
    }
  }
  getExpiryTime(format?: any) {
    let et: any = null;
    let hours = format.h ? parseInt(format.h) : 0;
    let minutes = format.m ? parseInt(format.m) : 0;
    let seconds = format.s ? parseInt(format.s) : 0;
    et = seconds + minutes * 60 + hours * 60 * 60; //Seconds
    let expireTime: any = new Date();
    expireTime.setSeconds(expireTime.getSeconds() + et);
    return expireTime.getTime();
  }
  getPresentTime() {
    return new Date().getTime();
  }
  extractData(res: Response) {
    if (res.status < 200 || res.status >= 300) {
      throw new Error("Response status: " + res.status);
    }
    return res.json() || {};
  }
  isStorageSupported(type = "sessionStorage"): boolean {
    return window && window[type] == undefined ? false : true;
  }
  isUserLoggedIn(): boolean {
    const isLoggedIn = this.storageService.getFromStorage("isLoggedIn");
      return isLoggedIn == "true" || isLoggedIn == true;
  }
  getLoggedInUserID(): string {
    const userDetails = JSON.parse(this.storageService.getFromStorage("ud") || "{}");

    return (this.isUserLoggedIn() && userDetails?.uid) ? userDetails.uid : false;
  }
  getLoggedInUserDetails(): any {
    const userDetails = JSON.parse(this.storageService.getFromStorage("ud") || "{}");

    return (this.isUserLoggedIn() && userDetails?.aboutme)? userDetails.aboutme : false;
  }
  login(email: string, password: any) {
    const params = { email, password, reqfrom: "pwaMSite" };
    const hdr_params = { "Content-Type": "application/x-www-form-urlencoded" };
    return this.postToAPI("/api/account/user/login", params, hdr_params, false);
  }
  gpLogin(params) {
    const hdr_params = { "Content-Type": "application/x-www-form-urlencoded" };
    return this.postToAPI(AuthorizationUrls.socialLogin, params, hdr_params, false);
  }
  register(email: any, name: any, gender: any, pass: any, phone: any) {
    const params = { email, name, gender, password: pass, reqfrom: "pwaMSite", phone };
    const hdr_params = { "Content-Type": "application/x-www-form-urlencoded" };
    return this.postToAPI("/api/account/user/register", params, hdr_params, false);
  }
  setIsUserLoggedIn(newVal: boolean) {
    this.setIsLoggedIn(newVal);
    this.storageService.setInStorage("isLoggedIn", newVal);
  }
  getIsLoggedIn() {
    return this.isLoggedIn.asObservable();
  }
  setIsLoggedIn(value) {
    this.isLoggedIn.next(value);
  }
  isEliteUser(): boolean {
    const isEliteUser = this.storageService.getFromStorage("isEliteUser");
    return isEliteUser == "true" || isEliteUser == true;
  }
  setIsEliteUser(newVal: boolean) {
    this.storageService.setInStorage("isEliteUser", newVal);
  }
  setLoggedInUserDetails(newVal: any) {
    const userDetails = JSON.parse(this.storageService.getFromStorage("ud") || "{}");

    if (newVal) {
      userDetails["uid"] = newVal["user"].id;
      userDetails["aboutme"] = newVal;
    } else {
      userDetails["uid"] = 0;
      userDetails["aboutme"] = false;
    }
    this.storageService.setInStorage("ud", JSON.stringify(userDetails));
  }
  getUserProps() {
    return JSON.parse(this.storageService.getFromStorage("props") || null);
  }
  setLoggedInUserID(newVal: any) {
    let userDetails = this.storageService.getFromStorage("ud");
    userDetails = userDetails? JSON.parse(userDetails) : {};
    userDetails["uid"] = newVal;

    this.storageService.setInStorage("ud", JSON.stringify(userDetails));
  }
  getPostLoginRedirectURL(): any {
    return this.redirectPostLogin;
  }
  setPostLoginRedirectURL(newVal: any) {
    this.redirectPostLogin = newVal;
  }
  getInitState(isReturn?: boolean, isNotLoadScripts?: boolean): any {
    return new Promise((resolve, reject) => {
      if (!this.isScriptsLoaded) {
        let iswebView = this.storageService.getCookie("is_webview") || this.storageService.getFromStorage("is_webview");
        if (
          (this.storageService.getFromStorage("modeDevice") == ModeDevice.mobile ||
            this.storageService.getFromStorage("modeDevice") == ModeDevice.desktop) &&
          this.storageService.getFromStorage("isRobot") != "true" &&
          ["false", false].includes(iswebView)
        ) {
          this.loadAllScripts(isNotLoadScripts);
        }
        this.isScriptsLoaded = true;
      }
      let isLoggedIn: boolean = false;
      let initstateApiRequest: boolean = false;
      if (!initstateApiRequest) {
        this.httpService.getFromApi(commonApiUrls.initState).then((res: any) => {
          this.isInitCalled = true;
          isLoggedIn = res["isLoggedIn"];
          this.commonService.showBeautyQuizOnBoardScreen = res?.enable_beautyquiz_onboarding;
          if (res["ab_testing"] && res["ab_testing"].blush_ab_testing) {
            this.commonService.newUIflag = this.storageService.getCookie("mode_device") == "desktop" ? true : res["ab_testing"].blush_ab_testing == "a" ? true : false;
          }
          if (res["cart"]) {
            if (typeof res["cart"]["items"] !== undefined && res["cart"]["items"] !== null && res["cart"]["items"] != false) {
              this.storageService.setItemsToCartList(res["cart"]["items"]);
            } else {
              this.storageService.unsetFromStorage("cartList");
            }
          }
          if (res["wishlist"]) {
            if (typeof res["wishlist"]["items"] !== undefined && res["wishlist"]["items"] !== null && res["wishlist"]["items"] != false) {
              this.storageService.setItemsToWishList(res["wishlist"]["items"]);
            } else {
              this.storageService.unsetFromStorage("wishlist");
            }
          }

          const priceRevealData = res["price_reveal_data"];
          if (priceRevealData && typeof priceRevealData == "object" && !Array.isArray(priceRevealData)) {
            if (priceRevealData?.beauty_box_items) {
              this.storageService.setItemsToGiftBoxCartList(priceRevealData?.beauty_box_items);
            } else {
              this.storageService.unsetFromStorage('giftBoxCart');
            }
            this.commonService.updateGiftBoxCartCount(priceRevealData?.beauty_box_count);
            this.setBeautyoxCartState(priceRevealData?.is_show_beauty_box);
          }

          if (res["logo"]) {
            this.storageService.setInStorage("logoData", JSON.stringify(res["logo"]));
          }
          this.storageService.setInStorage("props", JSON.stringify(res.props));
          this.storageService.setInStorage("screen_ab_testing", JSON.stringify(res.screen_ab_testing));
          this.commonService.updateCartCount(res["cart_count"]);
          this.commonService.updateWishlistCount(res["wishlist_count"]);
          if (res["isLoggedIn"] == true || res["isLoggedIn"] == "true") {
            window["user_id"] = res["userdetail"]["user"].id;
            window["userLogedIn"] = "true";
            window["about_me"] = res["userdetail"];

            if (res["is_elite"] === "1" || res["is_elite"] === 1) {
              this.setIsEliteUser(true);
            } else {
              this.setIsEliteUser(false);
            }
            this.setLoggedInUserID(res["userdetail"]["user"].id);
            this.setLoggedInUserDetails(res["userdetail"]);
            this.setIsUserLoggedIn(true);
            this.setLoginState(true);
          } else {
            window["userLogedIn"] = "false";
            this.setLoggedInUserID(0);
            this.setLoggedInUserDetails(false);
            this.setIsEliteUser(false);
            this.setIsUserLoggedIn(false);
            this.setLoginState(false);
          }
        }).finally(() => resolve(true));
      }
    });
  }
  loadAllScripts(isFromApp: any = false) {
    // gtm
    let env = "prod";
    if (this.storageService.getCookie("environment") && this.storageService.getCookie("environment") != "prod") {
      env = "dev";
    }
    let gtm_script =
      `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=` +
      (env == "prod" ? "0xkMqxx3dq0cYam3HVf5Kg" : "eXFsxbIc8jyRi8RYW-ZBJg") +
      `&gtm_preview=env-57&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WZ7HKG');`;
    let sandbox_n = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=2zbegQIDzAbiARgNRMzqzQ&gtm_preview=env-246&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-T7S22BF')`;
    let production_n = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=aWjfSkXTDyOW-6VkXjLkCQ&gtm_preview=env-247&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-T7S22BF');`;
    gtm_script = env == "prod" ? production_n : sandbox_n;
    !this.isSSR && this.loadScriptAsync(null, gtm_script, "gtm");
    if (!isFromApp) {
      // branch
      let branch_script =
        `(function (b, r, a, n, c, h, _, s, d, k) {if (!b[n] || !b[n]._q) {for (; s < _.length;)c(h, _[s++]); d = r.createElement(a); d.async = 1; d.src = "https://cdn.branch.io/branch-latest.min.js"; k = r.getElementsByTagName(a)[0]; k.parentNode.insertBefore(d, k); b[n] = h } })(window, document, "script", "branch", function (b, r) { b[r] = function () { b._q.push([r, arguments]) } }, { _q: [], _v: 1 }, "addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent".split(" "), 0);branch.init("` +
        (env == "prod" ? "key_live_jflMV8MKbvHiDMG3SAHJKmhlsCjCeLZk" : "key_test_lpnPJ3RRjANlCOG4KwOZQnjnEsfvdN1C") +
        `",{"make_new_link" : true}, function (err, data){console.log(data);});`;
      !this.isSSR && setTimeout(() => {
        this.loadScriptAsync(null, branch_script, "branch");
      }, 5000);

      let self = this;
    }
  }

  initializeChatBot = (userDetails) => {
    const { email = "", encodedUserId, phone } = userDetails;
    
    window["fcWidgetMessengerConfig"] = {
      email: email,
      phone: phone,
      meta: {
        cf_user_id: encodedUserId,
      },
      jsFunctions: {
        bracketRemoval: function (params) {
          let input = params.input;
          var lengthOfInput = input.length;
          var value = input.slice(1, lengthOfInput - 1);
          return { result: value };
        },
        statusCheck: function (params) {
          let theStatus = params.theStatus;
          var value = theStatus.includes("Delivery");
          return { result: value };
        },
      },  
    };
  }

  async loadChatBotScript() {
    const chatbotUrl = "//in.fw-cdn.com/30621492/337487.js";

    const userDetails = JSON.parse(this.storageService.getFromStorage("ud") || "{}");
    const props = JSON.parse(this.storageService.getFromStorage("props") || "{}");

    if (props?.encoded_userId && userDetails?.aboutme?.user) {
        userDetails.aboutme.user["encodedUserId"] = props.encoded_userId;
    }

    if (userDetails && props) {
        await this.loadScriptAsync(chatbotUrl, null, "chatbot");
        this.commonService.chatbotInitialized = true;
        this.initializeChatBot(userDetails?.aboutme?.user);
    }
  }

  loadScriptAsync(url: any = null, innerHtml: any = null, scriptType: string, element: any = "script") {
    return new Promise((resolve) => {
      let headOrBody = scriptType == "gtm" ? <HTMLDivElement>document.head : <HTMLDivElement>document.body;
      let script = document.createElement(element);
      if (innerHtml != null) {
        script.innerHTML = innerHtml;
      }
      if (url != null) {
        script.src = url;
      }
      if(scriptType == "chatbot") {
        script.setAttribute("chat", 'true');
      }
      script.type = "text/javascript";
      script.defer = true;
      headOrBody.appendChild(script);
      resolve(true);
    });
  }

  triggerLoginPopup(phoneVerificationMandatory: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!phoneVerificationMandatory && this.isUserLoggedIn()) {
        resolve(true);
      } else if (
        this.isUserLoggedIn() &&
        this.getLoggedInUserDetails() &&
        this.getLoggedInUserDetails().user &&
        this.getLoggedInUserDetails().user.is_verified_phone === 1
      ) {
        resolve(true);
      } else {
        this.showLoginPopupSub.next(true);
        this.loginSuccesSub = new Subject<any>();
        this.loginSuccesSub.subscribe((response) => {
          if (this.loginSuccesSub) {
            this.loginSuccesSub.unsubscribe();
          }
          this.showLoginPopupSub.next(false);
          if (response) {
            resolve(true);
          } else {
            reject(false);
          }
        });
      }
    });
  }
  showTruecallerLoginPopup(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const popupUrl = `truecallersdk://truesdk/web_verify?
            type=btmsheet&requestNonce=${this.storageService.getCookie('visitorppl')}
            &partnerKey=${environment.truecallerConfig}
            &partnerName=purplle
            &lang=en
            &termsUrl=${location.origin}/pr/terms-and-condition&loginPrefix=${this.getTruecallerLoginPrefix()}
            &loginSuffix=login
            &ctaPrefix=rect
            &ctaColor=%236600FF
            &ctaTextColor=white
            &btnShape=rect
            &skipOption=useanothernum`; // truecaller sdk response for useanothermethod/useanothernumber are same i.e used_another_number
      //@ts-ignore
      window.location = popupUrl.replace(/\s/g, "");
      setTimeout(() => {
        let returnType = !document.hasFocus();
        return resolve(returnType);
      }, 600);
    });
  }
  initiateTruecallerLogin() {
    let index = 1;
    let tries = 5;
    let apiCallCompleted = false;
    const timeout = timer(20 * 1000).pipe(
      tap((_) => {
        if (apiCallCompleted) throw { status: AuthApiStatus.timeout, message: AuthMessages.timeoutMessage };
      })
    );
    const url = TruecallerLoginUrls.loginUrl;
    const body = { requestId: this.storageService.getCookie("visitorppl") };
    const tcTokenApi = defer(() => this.httpService.postToAPI(url, body)).pipe(
      tap((res): void => {
        if (res.status === AuthApiStatus.pending) {
          throw { status: AuthApiStatus.pending };
        }
        else if (res.status === AuthApiStatus.failed) {
          apiCallCompleted = true;
          throwError({ status: AuthApiStatus.failed, message: res.message });
        }
      }),
      retryWhen((err) =>
        err.pipe(
          take(tries),
          delay(3000),
          mergeMap((error) => {
            if (++index > tries) {
              apiCallCompleted = true;
              return throwError({ status: AuthApiStatus.timeout, message: AuthApiStatus.timeout });
            }
            return of(error);
          })
        )
      )
    );
    return merge(tcTokenApi, timeout);
  }
  getTruecallerLoginPrefix(): TruecallerLoginPrefix {
    const url = this.requestService.getOriginalUrl();
    const urlParts = url.split("/");
    const path = urlParts[1]?.split("?")[0] || "";

    switch (path) {
        case "":
        case FHRoutes.home: return TruecallerLoginPrefix.getStarted;
        case AppExactRouteConstants.cartPage: return TruecallerLoginPrefix.checkout;
        case AppExactRouteConstants.profile: return TruecallerLoginPrefix.continue;
        default: return TruecallerLoginPrefix.continue;
    }
  }

  getModeDevice() {
    return this.storageService.getCookie("mode_device");
  }
}