export enum AuthApiStatus {
  failed = "failed",
  pending = "pending",
  timeout = "timeout",
  usedAnotherNumber = "use_another_number",
  userRejected = "user_rejected",
}
export enum AuthMessages {
  timeoutMessage = "Timeout",
}
export enum AuthStatus {
  UserLoggedIn = "UserLoggedIn"
}