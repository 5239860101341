import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { jsonToQueryString, parseURLQueryParams } from "@shared/utilities/parse-util";
import { Response } from "express";
import { Subject } from "rxjs";
import { RESPONSE } from "../../express.tokens";
import { StorageService } from "./storage.service";
import { TokenService } from "./token.service";
@Injectable({ providedIn: "root" })
export class HttpService {
  
  showErrorPopup: boolean = false;
  errorMsg: any;
  isSSR: boolean = typeof window === "undefined";
  queryParams = {};
  httpErrorSubject = new Subject<any>;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private injector: Injector,
    private storageService: StorageService,
    private http: HttpClient,
    private tokenService: TokenService
  ) {
    this.activatedRoute.queryParams.subscribe((params) => this.queryParams = typeof params !== "undefined" ? { ...params } : {});
  }

 
  handleRedirect({ url, response }: { url: string, response: any }): boolean {
    if (this.is404RedirectApiUrl(url)) {
      const resKeys = Object.keys(response);
      if (resKeys.indexOf("redirect_code") >= 0 && resKeys.indexOf("redirect_url") >= 0) {
        if (this.isSSR) {
          const res = this.injector.get<Response>(RESPONSE);
          // apending previous url params to new redirect url - keeping campaign data for the 301 redirect cases
          const lastUrlQueryParams = res?.["req"]?.["query"];
          const redirectUrl = lastUrlQueryParams && Object.keys(lastUrlQueryParams).length > 0 ? parseURLQueryParams(response["redirect_url"], lastUrlQueryParams) : response["redirect_url"];
          res.redirect(response["redirect_code"], redirectUrl);
          res.end();
        } else {
          const redirectUrl = new URL(response["redirect_url"]);
          const navURL = parseURLQueryParams(redirectUrl?.pathname + redirectUrl?.search, this.queryParams);
          this.router.navigateByUrl(navURL, { replaceUrl: true });
        }
        return true;
      }
    }
    return false;
  }

  async  postToAPI(url: string, paramsObj: any, configs = {} as any) {
    const { headersObj, mapped, setHeader, skipHandleErr, skipJsonToQuery, isOnlyCustomHeader, showHttpErrorPopup = true } = configs || {};
    const payload = skipJsonToQuery ? { ...paramsObj } : jsonToQueryString(paramsObj);
    const sessionHeaders = isOnlyCustomHeader ? new HttpHeaders() : this.storageService.getRequestHeaders();
    if (!sessionHeaders.get("token") || typeof sessionHeaders.get("token") === "undefined") {
      console.log("failedReq:", url);
      console.log("failedReqHeaders:", sessionHeaders);
    }
    const headers = this.getFinalHeader({ sessionHeaders, headersObj, isOnlyCustomHeader });
    return this.http
      .post(url, payload, { headers })
      .toPromise()
      .then((response) => response)
      .catch((err) => (skipHandleErr ? console.log(err) : this.handleErrors(err, '', showHttpErrorPopup)));
  }
  
   async getFromApi(url, config = {} as any) {
    const { headersObj, skipHandleErr, serverSideLoggedInStatus, isGenericCall, isOnlyCustomHeader, showHttpErrorPopup = true } = config || {};
    const isUseGenericHeader = isGenericCall && !(!this.isSSR || serverSideLoggedInStatus === true);
    const sessionHeaders = isOnlyCustomHeader ? new HttpHeaders() :
      isUseGenericHeader ? this.storageService.getGenericRequestHeaders() : this.storageService.getRequestHeaders();
    if (!isUseGenericHeader && (!sessionHeaders.get("token") || typeof sessionHeaders.get("token") === "undefined")) {
      console.log("failedReq:", url);
      console.log("failedReqHeaders:", sessionHeaders);
    }
    const headers = this.getFinalHeader({ sessionHeaders, headersObj, isOnlyCustomHeader });
    return this.http.get(url, { headers })?.toPromise().then((response) => {
      let redirected: boolean = false;
      if (isGenericCall) { redirected = this.handleRedirect({ url, response }); }
      if (!redirected) { return response };
      return null;
    }).catch((err) => (skipHandleErr ? console.log(err) : this.handleErrors(err, url, showHttpErrorPopup)));
  }
  
    getHttpError(): any {
    return this.httpErrorSubject.asObservable();
  }
    setHttpError(value) {
    this.httpErrorSubject.next(value);
  }
    showErrorMsg(msg: any) {
    if (msg != null) {
      this.setHttpError({ msg, statusCode: 400 });
    }
  }
  
  getFinalHeader({ sessionHeaders, headersObj, isOnlyCustomHeader = false }: { sessionHeaders: HttpHeaders, headersObj: any, isOnlyCustomHeader: boolean }): HttpHeaders {
    if (headersObj != null) {
      for (var key in headersObj) {
        sessionHeaders = sessionHeaders.set(key, headersObj[key]);
      }
    }
    if(!isOnlyCustomHeader) {
      sessionHeaders =  sessionHeaders.set('is_SSR', String(this.isSSR));
    }
    return sessionHeaders;
  }
  
  async handleErrors(error: any, apiUrl: string = null, showHttpErrorPopup: boolean = true): Promise<any> { 
    console.log('API Error', error?.error, apiUrl);  
    if (error.status === 401 && error["error"] && error["error"]["action"] && error["error"]["action"] == "update") {
      this.tokenService.updateToken();
      return;
    } else if (error.status === 401) {
      this.tokenService.generateToken();
      return;
    } else if (error.status === 404 && apiUrl && this.is404RedirectApiUrl(apiUrl)) {
      if (this.isSSR) {
        const res = this.injector.get<Response>(RESPONSE);
        res.status(404);
      }
      this.router.navigate(["/404"], { skipLocationChange: true });
    } else {
      if (showHttpErrorPopup && error && error.error && error.error.message) {
        this.showErrorMsg(error.error.message);
      }
    }
    return Promise.reject(error.error || error);
  }
  
  is404RedirectApiUrl(apiUrl: string): boolean {
    const apiUrlList = [
      "/items", "/basicinfo", "/description",
      "/reviews", "/listing", "/find_category_id_by_slug",
      "/find_brand_id_by_slug", "/thread_detail", "/storydetail",
      "/stories",
    ];
    return apiUrlList.some(path => apiUrl.indexOf(path) >= 0);
  }
  
}
