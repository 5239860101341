export enum AppRouteConstants {
  cartPage = "/cart",
  offersPage = "/offers",
  home = "/",
  login = "/login",
  search = "/search",
  profile = "/profile",
  addAddress = "/profile/myaddresses/add",
  myPayment = "/profile/payment",
  changePassword = "/profile/changepassword",
  editProfile = "/profile/editprofile",
  myReviews = "/profile/myreviews",
  myFavourites = "/profile/myfavourites",
  myaddresses = "/profile/myaddresses",
  profileOrders = "/profile/orders",
  orderDetails = "/profile/order/",
  cancelOrder = "/profile/order/cancel/",
  trackOrder = "/profile/order/track/",
  purplleCredit = "/profile/purpllecredit",
  purplleCreditDetails = "/profile/purpllecredit/details",
  purplleCreditTermsAndConditions = "/pr/purplle-credit-terms-and-conditions",
  deleteAccount = "/profile/delete_account",
  beautyBox = "wv/price-reveal/beautybox",
  pageNotFound = "/404",
  cart = "/cart",
  checkout = "/checkout",
  payment = "/checkout/payment",
  ticketForm = "/wv/support/ticket/form",
  support = "/wv/support",
  supportPage = "/wv/support/home",
  ticketFormSubmitPage = "/wv/support/ticket/submit",
  beautyQuiz = "/wv/beautyquiz/62145676519057c4eb61cd51",
  beautyProfile = "/profile/beautyprofile",
  editAddress = "/profile/myaddresses/edit",
  elite = "/wv/elite",
  lp = "/lp",
  product = "/product/",
  categories = "/wv/categories",
  beautystudio = "/beautystudio",
  explore = "/explore",
  brand = "/brand",
  tryon = "/wv/virtual-try-on",
  catalog = "/catalog/reco",
  freebies = "/wv/freebies",
  rewards = "/wv/rewards",
  deliveryFeedback = "/deliveryfeedback",
  feedback = "/feedback"
}

export enum AppExactRouteConstants {

  cartPage = "cart",
  profile = "profile",

  elite = "elite",
  appDownload = "app-download",
  brand = "brand",
  explore = "explore",
  categories = "categories",
  viewall = "viewall",
  pageNotFound = '404',
  error = 'error',
  offline = 'offline',
  skinAnalyzer = "skinAnalyzer"
}

export enum AppRoutesWithParams {
  home = "home",
  offers = "offers",
  magazine = "magazine",
  lp = "lp",
  lph = "lph",
  product = "product",
  profile = "profile",
  discover = "discover",
  webview = "wv",
  mbh = "mbh",
  beautystudio = "beautystudio",
  checkout = "checkout",

  deals = "deals",

  pr = "pr",
  feedback = "feedback",
  deliveryfeedback = "deliveryfeedback",
  catalog = "catalog",
  elite = "elite"
}

