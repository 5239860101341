
function jsonToQueryString(json) {
    return Object.keys(json).map(function (key) {
        return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
    }).join("&");
}

function parseURLQueryParams(URL: string, params) {
    const obj = Object.entries(params).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {})
    let parsedStr = jsonToQueryString(obj);
    if (URL.split('?').length > 1) return `${URL}&${parsedStr}`;
    return `${URL}?${parsedStr}`;
}

export { jsonToQueryString, parseURLQueryParams };
