import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ModeDevice } from "../constants/application/application-constants";
import { StorageService } from './storage.service';

@Injectable({providedIn: 'root'})
export class TokenService {
  constructor(private http: HttpClient, private storageService: StorageService) {}
  generateToken(visitorppl?: any) {
      if (!visitorppl) { visitorppl = this.storageService.getCookie("visitorppl"); }
      const deviceType = this.storageService.getCookie("mode_device");
      return new Promise((resolve) => {
        let headers = new HttpHeaders();
        headers = headers.set("Content-Type", "application/x-www-form-urlencoded");
        headers = headers.set("type", deviceType || ModeDevice.mobile);
        headers = headers.set("mode_device_type", "web");
        if (visitorppl) { headers = headers.set("device_id", visitorppl) };
        this.http.get("/neo/auth/get/v1", { headers })
          .toPromise().then((result) => {
            if (result && result["token"] && typeof result["token"] !== "undefined") {
              this.storageService.setCookie("token", result["token"]);
              resolve(result["token"]);
            }
          }).catch((err) => { 
            resolve(null); 
          });
      });
    }
    updateToken() {
      const deviceType = this.storageService.getCookie("mode_device");
      const token = this.storageService.getCookie("token");
      const visitorppl =this.storageService.getCookie("visitorppl");
      let headers = new HttpHeaders();
      headers = headers.set("Content-Type", "application/x-www-form-urlencoded");
      headers = headers.set("type", deviceType || ModeDevice.mobile);
      headers = headers.set("mode_device_type", "web");
      if (token) { headers = headers.set("token", token); }
      if (visitorppl) { headers = headers.set("device_id", visitorppl); }
      this.http.post("/neo/auth/update/v1", {}, { headers })
        .toPromise()
        .then((result) => {
          if (result && result["token"] && typeof result["token"] !== "undefined") {
            this.storageService.setCookie("token", result["token"]);
          }
        }).catch(async (error) => {
          if (error.status === 401) {
            this.generateToken();
          }
        });
    }
    deleteToken() {
      const deviceType = this.storageService.getCookie("mode_device");
      const token = this.storageService.getCookie("token");
      const visitorppl = this.storageService.getCookie("visitorppl");
      let headers = new HttpHeaders();
      headers = headers.set("Content-Type", "application/x-www-form-urlencoded");
      headers = headers.set("type", deviceType || ModeDevice.mobile);
      headers = headers.set("mode_device_type", "web");
      if (token) headers = headers.set("token", token);
      if (visitorppl) headers = headers.set("device_id", visitorppl);
      this.http.post("/neo/auth/delete/v1", {}, { headers })
        .toPromise().then((result) => { });
    }
}
