import { FirebaseOptions } from "firebase/app";
import { envFireConfig } from "./env-fire";

export function getGenericToken(isSSR): string {
    if (isSSR) {
        if (process.env['ENVIRONMENT'] == 'sandbox' || process.env['ENVIRONMENT'] == 'dev') {
            return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZpY2VfaWQiOiJrT2psVjc4ZmcwcXpvcDRvWGciLCJtb2RlX2RldmljZSI6Im1vYmlsZSIsIm1vZGVfZGV2aWNlX3R5cGUiOiJ3ZWIiLCJpYXQiOjE2MDE5NjMwMjIsImV4cCI6NTYwOTczOTAyMiwiYXVkIjoid2ViIiwiaXNzIjoidG9rZW5taWNyb3NlcnZpY2UifQ.QBFP8xhBWsqJKp17M1maT0_bgQW3cM1YFy6YCtB26l8';
        }
        else {
            return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZpY2VfaWQiOiJLSXdZY1Z2Yzgwdld2QmV1TUoxMjcwMDExNTgwMjA1ODY3IiwibW9kZV9kZXZpY2UiOiJkZXNrdG9wIiwibW9kZV9kZXZpY2VfdHlwZSI6IndlYiIsImlhdCI6MTU4NDA4NjYzOCwiZXhwIjoyNjkwNjQ3NTI0LCJhdWQiOiJ3ZWIiLCJpc3MiOiJ0b2tlbm1pY3Jvc2VydmljZSJ9.RdrqkTAPBDh0Qe-605a_dOYoXOOPcJe33f6tuMioKi8';
        }
    } else { return window['generic_token']; }
}

export function getGenericDeviceId(isSSR): any {
    if (isSSR) {
        if (process.env['ENVIRONMENT'] == 'sandbox' || process.env['ENVIRONMENT'] == 'dev') { return 'kOjlV78fg0qzop4oXg'; }
        else { return 'KIwYcVvc80vWvBeuMJ1270011580205867'; }
    } else { return window['generic_device_id']; }
}

export function getBaseOrigin(isSSR, isProduction): any {
    if (isSSR) {
        return isProduction ? process.env['BASE_URL'] : localDeployedUrl
    } else {
        if (location && location.origin && location.origin.indexOf('purplle.com') == -1 && isProduction) {
            console.log('webCacheOrigin', origin);
            return productionUrl;
        }
        return location.origin;
    }
}

export function getIsProduction(isSSR) {
    if (!isSSR) {
        if (location && location.origin && location.origin.indexOf('purplle.com') != -1) { return true; }
        return false;
    }
    return process.env['ENVIRONMENT'] ? true : false;
}

export function getUpstreamUrl(isProduction): string {
    if (isProduction) { return ''; }
    return `https://sandbox.purplle.com`;
}

export function getBaseServerUrl(isProduction): string {
    return isProduction ? productionUrl : localDeployedUrl;
}

export function getCustomerGluConfig(isSSR): any {
    const authToken = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJ0ZXN0dXNlcjEiLCJjbGllbnQiOiIyZjIxYTZkYy04ODZkLTQ5NDEtODUzMi00MDkwMmYyNzM4NzEiLCJkZXZpY2VJZCI6ImRldmljZWEiLCJkZXZpY2VUeXBlIjoiYW5kcm9pZCIsImlhdCI6MTYwMDQ0MzI4MCwiZXhwIjoxNjMxOTc5MjgwfQ.oDDosxNc0XWmBFG0H7KiZIweDxA8sBeWbCWqMtgwDKg';
    let writeKey = 'dtnzXMynYCsxGkuU7srD4df6FZ3Eu45j';
    if (!isSSR && location && location.host && (location.host.includes('www') || location.host.includes('test'))) {
        writeKey = 'vTPCpHfVel2IZo09SfgWpGETzIetZmr6qLkGtYoj';
    }
    return { authToken, writeKey };
}

export function getRVTryonConfig(): any {
    const rvTryOnConfig = {
        revieveJsUrl: 'https://d38knilzwtuys1.cloudfront.net/revieve-plugin-v4/revieve-plugin-loader.js',
        fullLookPartnerId: 'C7ED7YA9er',
        singleLookPartnerId: 'NHWnFyxtQo',
        bestSellerlookPartnerId: 'YTxitc7JLF',
        weddingFullLookPartnerId: 'cE3OpC4OZD',
        partyFullLookPartnerId: 'CFVFIoJsMP',
        everydayFullLookPartnerid: 'PBKS54tJYE',
        language: 'en',
    };
    return rvTryOnConfig;
}

export function getTruecallerTokenConfig(isSSR): string {
    const sandboxToken = 'ychpg52bf6c1741b949929158fd8ee1fcba0d';
    const testToken = 'YOIje99f23b220eb64c0cb048ce6e9b8da32e';
    const prodToken = 'SIlGH12f8e6ea8b84480da95b2b58dd5e277a';
    if (!isSSR) {
        if (location && location.host && location.host.includes('www')) {
            return prodToken;
        } else if (location && location.host && location.host.includes('test')) {
            return testToken;
        } else {
            return sandboxToken;
        }
    } else { return testToken; }
}
export function getWebviewVersion() {
    return 'wv.0.1'
}
export function getDefaultFirebaseConfig(isSSR): FirebaseOptions {
    const { agoraFirebaseConfig, authFirebaseConfig, messageFirebaseConfig } = getFirebaseConfig(isSSR);
    if (!isSSR) {
        if (location && location.host && (location.host.includes('www') || location.host.includes('test'))) {
            return authFirebaseConfig;
        } else {
            return messageFirebaseConfig;
        }
    } else {
        return (['sandbox', 'dev'].includes(process.env['ENVIRONMENT'])) ? messageFirebaseConfig : authFirebaseConfig;
    }
}

export function getFirebaseConfig(isSSR): { agoraFirebaseConfig: FirebaseOptions, authFirebaseConfig: FirebaseOptions, messageFirebaseConfig: FirebaseOptions } {
    if (envFireConfig && typeof envFireConfig == 'string') {
        //decode and get firebase config options
        const config = isSSR ? Buffer.from(envFireConfig, 'base64').toString('utf-8') : atob(envFireConfig);
        return JSON.parse(config)
    }
    return {} as any;
}

export function getEnvironment() {
    if(location && location.origin && location.origin == 'https://test.purplle.com') {
        return "test";
    } else if(location && location.origin && location.origin =='https://www.purplle.com') {
        return "prod";
    } else return "dev";
}

export const googleClientIdConst = '891596427770-fntqhbmhvbn12q15psjnqvvts9nvbp3q.apps.googleusercontent.com';
export const productionUrl = 'https://www.purplle.com';
export const localDeployedUrl = 'http://localhost:4200';
export const purplleDomains = ["localhost", "https://sandbox.purplle.com", "https://test.purplle.com", "https://www.purplle.com"];
